<template>
  <BaseDialog ref="baseDialog" top="5vh" width="500px" :dialogVisible.sync="dialog" :title="title">
    <template>
      <BaseForm v-loading="loading" ref="form" label-width="100px" :cols="formField" :form="form" :formVisible="dialog">
        <template #thumbnail_pathSlot="{ scoped: { prop } }">
          <ImgUpload
            ref="imgUpload"
            action="#"
            :hideDialog="true"
            :showFileName="true"
            :hiddenUploadBtn="true"
            :limit="1"
            :title="data.title"
            :files.sync="form[prop]"
          />
        </template>
        <template #pathSlot="{ scoped: { prop } }">
          <ImgUpload
            ref="imgUpload"
            class="fileUpload"
            action="#"
            :showFileName="true"
            :limit="1"
            :files.sync="form[prop]"
          />
        </template>
      </BaseForm>
    </template>
    <template #footer>
      <LoadingBtn type="primary" @click="doSubmit"> 确认 </LoadingBtn>
      <LoadingBtn @click="cancel"> 取消 </LoadingBtn>
    </template>
  </BaseDialog>
</template>
<script>
import ImgUpload from '@/components/fileUpload/imgUpload'
import formField from './field'
import { update, changePublicUpdate as publicUpdate } from '@/api/imageApi'
import { compressFile, getPicName } from '@/utils'
import { commonFromMixin } from '@/mixins'

export default {
  mixins: [commonFromMixin],
  components: {
    ImgUpload
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    isPublic: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formField,
      loading: false
    }
  },

  methods: {
    isSamePic(name) {
      const { title } = this.data
      return title === name
    },

    //上传oss图片
    async uploadOssPic(pathFile, thumFile) {
      return await $uploadOSSPics([
        {
          files: [pathFile],
          dirPrefix: $ossDirMapWithType['0'],
          prop: 'path'
        },
        {
          files: [thumFile],
          prop: 'thumbnailPath',
          dirPrefix: $ossDirMapWithType['0'],
          uuidSuffix: '_t'
        }
      ])
    },

    async doSubmit() {
      const valid = await this.$refs['form'].validate()
      if (!valid) return
      const {
        path: [file]
      } = this.form
      if (!this.isSamePic(getPicName(file.name))) {
        return this.$message.error('原图上传有误， 请确保和更换前原图名一致。')
      }
      this.loading = true

      const { id } = this.data
      try {
        const { naturalWidth: width, naturalHeight: height, file: thumbnail } = await compressFile(file)

        const ossData = await this.uploadOssPic(file, thumbnail)
        //判断oss图片是否上传成功
        if (!ossData) {
          this.loading = false
          return
        }

        const formData = {
          id,
          height,
          width,
          ...ossData
        }

        const api = this.isPublic ? publicUpdate : update
        const { code } = await api(formData)
        if ($SUC({ code })) {
          setTimeout(() => {
            this.success()
          }, 100)
        }
      } catch (err) {
        console.log('err', err)
      }
      this.loading = false
    }
  }
}
</script>
